.invoice {
  position: relative;
  margin: 0 auto;
  width: 18rem;
  padding: 20px;
  background-color: #fff;
  box-shadow: none;
  border: none;
  overflow-y: auto;
  height: 85vh;
}

.invoice-content {
  padding-bottom: 50px;
}

.invoice-header {
  text-align: center;
  margin-bottom: 10px;
}

.invoice-details {
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.invoice-table {
  width: 100%;
  /* background-color: aqua; */
  border: none;
  /* border-collapse: collapse; */
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.invoice-table th {
  /* align-items: flex-end; */
  background-color: transparent;
  border: none;
  font-weight: bold;
  text-align: left;
  padding: 5px;
}

.invoice-table td {
  /* align-items: flex-end; */
  border: none;
  padding: 5px;
}

.invoice-table .price_td {
  text-align: center;
  /* border: none;
  padding: 5px; */
}

.unit-price {
  font-size: 0.8rem;
  /* color: #666; */
}

.invoice-table .price {
  text-align: center;
  /* background-color: rgb(177, 9, 9); */
}

.invoice-total {
  text-align: left;
  margin-top: 10px;
  font-size: 0.9rem;
}

.underline tbody td {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: grey;
  width: 100%;
}

.underline th {
  text-decoration: none; /* Remove underline for <th> */
}

.row {
  display: flex;
}

.column {
  flex: 1;
}

.column p {
  margin: 0;
  text-align: right;
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .invoice {
    position: relative;
    margin: 0 auto;
    max-width: 280px;
    height: auto; /* Set to auto for printing */
    padding: 20px;
    background-color: #fff;
    box-shadow: none;
    border: none;
    page-break-before: always;
    font-size: 0.8rem;
  }

  .invoice-content {
    padding-bottom: 50px;
  }



  button {
    display: none;
  }
}
