.content {
  display: flex;
  flex-wrap: wrap;
}

.banner-delete-btn {
  /* position: absolute; */
  /* top: 5px; */
  /* right: 5px; */
  /* border-radius: 25%; */
  /* background: black; */
  border: none;
  color: red;
  font-size: 1rem;
  cursor: pointer;

  position: absolute;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%) rotate(-45deg); */
  width: 100%;
  height: 100%;
  padding: 20px;
  /* box-sizing: border-box; */
  background-color: white;
  opacity: 0;
  transition: all 0.35s linear ;
}
.bannercard:hover .banner-delete-btn {
  /* display: block; */
  /* transform: translate(-50%, -50%) rotate(0deg); */
  opacity: 1;
}

.bannercard {
  cursor: pointer;
  position: relative;
  width: 23.6rem;
  height: 10rem;
  /* background: linear-gradient(-45deg, #efefef 0%, #efefef 100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); */
  vertical-align: top;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bannercard img {
  width: 100%;
  height: 100%;
  object-fit: fit;
}

.dropzone-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.bannercard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.banner-images {
  display: flex;
  flex-wrap: wrap;
}
