.height-excel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.container-excel {
 position: relative;
  /* top: 30%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* max-width: 400px; */
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title-excel {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.file-input-excel {
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.send-button-excel {
  font-size: 16px;
  padding: 10px 20px;
  color: #fff;
  background-color: var(--mainColorDark);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button-excel:hover {
  background-color: var(--mainColorLight);
}
