@charset "UTF-8";
/* ==== CUSTOM UI ELEMENTS AND STYLES ====*/
/* ========================
INCLUDE STYLES AND COMPONENTS
===================== */
/* ================== HELPERS =================== */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  /* font-family: Arial, sans-serif; */
}

:root {
  --mainColor: white;
  --mainColorLight: #a0c73a;
  --mainColorDark: #7bab47;
  --secondaryDarkColor: #304424;
  /* #7bab47 */
  --secondaryColor: #f4e681;
  --textColor: black;
}

.brand-wrap {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.brand-content {
  /* display: flex; */
  /* align-items: center; */
  /* background-color: #000; */
  /* overflow: none; */
  overflow: hidden !important;
  /* height: 4.5rem; */
  margin-top: 1rem;
}
.brand-content img {
  width: 100%;
  /* height: 100%; */
  /* height: min-content; */
  overflow: hidden !important;
  /* margin-top: 1rem; */
  background-color: #000;
  /* display: flex;
  align-items: center;
  background-color: #000; */
}
.brand-name {
  color: rgb(2, 175, 2);
}

.brand-market {
  color: rgb(2, 175, 2);
  text-align: center;
  margin-left: 15px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
/* Disable default number input styling */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.red-snackbar {
  color: red;
}
/* Invoice.css */

.invoice {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.invoice-header {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-header h1 {
  margin: 0;
}

.invoice-details {
  margin-bottom: 20px;
}

.invoice-details p {
  margin: 5px 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.invoice-total {
  text-align: right;
  margin-top: 20px;
}

@media print {
  body * {
    visibility: hidden;
  }

  .invoice,
  .invoice * {
    visibility: visible;
  }

  .invoice {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .hidden-print,
  .hidden-print * {
    display: none !important;
  }

  .invoice {
    page-break-inside: avoid;
    page-break-after: always;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0);
  /* padding: 20px; */
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* max-width: 80%; */
  /* max-height: 100%; */
  overflow: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.semi-bold {
  font-weight: 600;
}

.product-column {
  display: flex;
  align-items: left;
}

.product-column {
  /* width: 100%; */
  text-align: left;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  text-align: left;
}

.total-right-align {
  /* display: inline-block;
  text-align: right; */
  float: right;
}

.underline {
  border-bottom: 1px dashed #000;
  margin-bottom: 10px;
}

.total-right-align {
  text-align: right;
}

.form-select {
  cursor: pointer;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="date"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .row.gx-3.py-3 {
    flex-direction: column;
    align-items: stretch;
  }

  .col-lg-6.col-md-4,
  .col-lg-6.col-md-8.col-sm-12.text-white {
    width: 100%;
    margin-bottom: 10px;
  }

  /* .container {
    flex-direction: column;
    align-items: stretch;
  } */

  .col-lg-4.col-md-6 {
    width: 100%;
    margin-bottom: 10px;
  }
}

a.disabled {
  pointer-events: none;
}

.btn-primary {
  background-color: var(--mainColorLight) !important;
  border: 1px solid var(--mainColorLight) !important;
}

.Header-green {
  background-color: var(--mainColorLight);
}

/* =================  DEFINED VARIABLES =================== */
.icon-xs,
.icon-sm,
.icon-md,
.icon-lg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon-xs {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  font-size: 16px;
}

.icon-sm {
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  font-size: 20px;
}

.icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px !important;
  font-size: 28px;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  font-size: 42px;
}

.img-avatar {
  border-radius: 100%;
  border: 3px solid #fff;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
}

.img-xs {
  width: 40px;
  height: 40px;
}

.img-sm {
  width: 60px;
  height: 60px;
}

.img-md {
  width: 112px;
  height: 112px;
}

.img-lg {
  width: 196px;
  height: 196px;
}

.center-xy {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.center-y {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.center-x {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* ================== BASE =================== */
body {
  position: relative;
  min-height: 100vh;
}

body.offcanvas-active {
  overflow: hidden;
}

.main-wrap {
  margin-left: 13.5rem;
  margin-right: 0.4rem;
  /* background-color: #f8f9fa; */
}

.content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
}
.navbar-aside.minimized {
  width: 100px;
}
.btn-aside-minimize {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 10px;
}
.navbar-aside {
  max-width: 16rem;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 13rem;
  overflow-x: hidden;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 10;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
}

.navbar-aside .aside-top {
  overflow-x: hidden;
  padding: 1rem 0.5rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-aside .aside-top .brand-wrap {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}

.navbar-aside .aside-top .logo {
  height: 2rem;
  width: auto;
  max-width: 200px;
}

.main-header {
  padding: 0rem 2rem 0rem 2.8rem;
  min-height: 72px;
  background-color: #fff;
  border-bottom: 2px solid rgba(108, 117, 125, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* justify-content: space-between; */
}
.name {
  color: var(--mainColorDark);
  margin-left: 1.85rem;
}
.text_overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* .main-header .nav {
  align-items: center;
 display: flex;
justify-content: space-around;
/* justify-items: self-end; */

/* } */

.main-header .nav-item > a {
  border-radius: 0.25rem;
  color: #adb5bd;
  display: block;
  text-decoration: none;
}

.main-header .nav-link:hover {
  background-color: #e9ecef;
  color: var(--mainColorDark);
}

.main-header .col-search {
  flex-grow: 0.5;
}

.main-header .col-nav {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;
}
.dropdown-end {
  margin-left: auto;
  color: #000;
}
.nav-item img.rounded-circle {
  border: 2px solid #eee;
}

.nav-item img.rounded-circle:hover {
  border-color: var(--mainColorLight);
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.content-header .content-title {
  margin-bottom: 0;
}

.inner {
  padding: 1rem;
}

body.aside-mini .main-wrap {
  margin-left: 5.4rem;
}

body.aside-mini .navbar-aside {
  max-width: 6.3rem;
  overflow: visible;
  position: absolute;
  min-height: 100%;
  bottom: auto;
}

body.aside-mini .navbar-aside .aside-top {
  text-align: center;
}

body.aside-mini .navbar-aside .aside-top > div {
  flex-grow: 1;
}
body.aside-mini .menu-heading {
  width: 4.2rem;
  text-overflow: clip;
  overflow: hidden;
}
body.aside-mini .iconfig {
  display: none;
  /* width: 4.2rem;
  text-overflow: clip;
  overflow: hidden; */
}
body.aside-mini .navbar-aside .brand-wrap,
body.aside-mini .navbar-aside .logo {
  display: none;
}

body.aside-mini .menu-aside .menu-link {
  text-align: center;
  width: 3rem;
}

body.aside-mini .menu-aside .menu-link .text {
  display: none;
}

body.aside-mini .menu-aside .menu-link .icon {
  margin-right: 0;
}

body.aside-mini .menu-aside .submenu {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  margin-left: 0;
  padding: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 180px;
}

body.aside-mini .menu-aside .menu-item {
  position: relative;
}

body.aside-mini .menu-aside .menu-item:hover .submenu {
  display: block;
  z-index: 10;
}

body.aside-mini .menu-aside .menu-item.has-submenu .menu-link:after {
  display: none;
}

.menu-aside {
  /* width: 12rem; */
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}

.menu-aside a {
  display: block;
  text-decoration: none;
}

.menu-aside .menu-item {
  margin-bottom: 5px;
  font-size: 1.03rem;
  font-weight: bold;
}

.menu-aside .menu-item .icon {
  color: #adb5bd;
  margin-right: 10px;
  font-size: 18px;
}

.menu-aside .active .icon {
  color: var(--mainColorDark);
}

.menu-aside .active {
  background-color: #ebf0fd;
}

.menu-aside .menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-aside .menu-link-header {
  padding: 10px 10px 10px 0px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-aside .menu-link .text {
  vertical-align: middle;
}

.menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}

.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}

.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}

.menu-aside .submenu a:hover {
  color: #000;
}

.menu-aside .menu-item.active .submenu {
  display: block;
}

.menu-aside .menu-item.has-submenu > .menu-link:after {
  display: inline-block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  vertical-align: middle;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.nav-pills .nav-link {
  color: #6c757d;
  font-weight: 500;
}

.nav-pills .nav-link:hover {
  color: #141432;
  background-color: rgba(173, 181, 189, 0.15);
}

/* --------------------- titles ---------------------- */
.section-heading {
  margin-bottom: 40px;
  margin-top: 0;
}

.section-heading p {
  max-width: 600px;
  margin: auto;
}

.section-title {
  font-weight: 500;
}

.title-text {
  margin-top: 45px;
  margin-bottom: 20px;
}

.b {
  font-weight: 600;
}

/* ==================  title helpers ================ */
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* ================== COMPONENTS =================== */
a[class*="card"] {
  color: initial;
}

a[class*="card"]:hover .title {
  color: var(--mainColorLight);
}

/* ITEM LIST */
.itemlist {
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.itemlist:hover {
  background-color: rgba(49, 103, 235, 0.075);
}

/* ITEMSIDE */
.itemside {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.itemside .aside,
.itemside .left {
  position: relative;
  flex-shrink: 0;
}

.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

.itemside p {
  margin-bottom: 0;
}

.itemside .title {
  display: block;
  margin-bottom: 5px;
}

.itemside a.title:hover {
  color: var(--mainColorLight);
}

a.itemside {
  color: initial;
  text-decoration: none;
}

a.itemside:hover .title {
  text-decoration: underline;
}

/* BASE STYLE FOR PRODUCT ITEM */
[class*="card-product"] {
  border: 1px solid rgba(108, 117, 125, 0.25);
}

[class*="card-product"] p {
  margin-bottom: 0;
}

[class*="card-product"] .img-wrap {
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
  background: white;
}

[class*="card-product"] .img-wrap img {
  height: 100%;
  max-width: 100%;
  width: auto;
  display: inline-block;
  object-fit: cover;
}

/* GRID STYLE PRODUCT ITEM */
.card-product-grid {
  margin-bottom: 20px;
}

.custom-btn {
  width: 95%;
  height: 40px;
}

.card-product-grid .img-wrap {
  border-radius: 0.2rem 0.2rem 0 0;
  height: 220px;
}

.card-product-grid .info-wrap {
  padding: 1rem;
}

.card-product-grid a.title {
  color: #6c757d;
  display: block;
  text-decoration: none;
}

.card-product-grid a.title:hover {
  color: var(--mainColorLight);
}

.card-product-grid:hover {
  border-color: #adb5bd;
}

/* LARGE LIST STYLE PRODUCT ITEM */
.card-product-list {
  margin-bottom: 20px;
}

.card-product-list .img-wrap {
  height: 220px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.card-product-list .info-main {
  padding: 1.5rem 1rem;
}

.card-product-list .info-aside {
  padding: 1.5rem 1rem;
  border-left: 1px solid rgba(108, 117, 125, 0.25);
  height: 100%;
}

.card-user {
  margin-bottom: 20px;
}
.card-header {
  background-color: var(--mainColorDark);
}
.card-user .card-header {
  position: relative;
  height: 100px;
  background-color: var(--mainColorDark) !important;
  text-align: center;
}

.card-user .img-avatar {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
}

.card-user .card-body {
  text-align: center;
}

/* --------- description list --------*/
[class*="dlist"] {
  margin-bottom: 5px;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist {
  display: flex;
}

.dlist dt {
  width: 150px;
  font-weight: normal;
}

.dlist dd {
  margin-left: 30px;
  vertical-align: baseline;
  flex-grow: 1;
  margin-bottom: 0;
  text-align: right;
}

/* -------------------- list.row--------------------- */
ul.row,
ul.row-sm {
  list-style: none;
  padding: 0;
}

/* ================= lists ================= */
[class*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden;
  height: 0;
  clear: both;
}

.list-icon {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.list-icon li {
  margin-bottom: 7px;
  position: relative;
  padding-left: 30px;
}

.list-icon .icon {
  width: 22px;
  margin-right: 15px;
  vertical-align: middle;
  text-align: center;
  color: #6c757d;
  position: absolute;
  top: 3px;
  left: 0;
}

.list-icon span {
  vertical-align: middle;
}

.list-bullet {
  list-style: none;
  padding-left: 0;
}

.list-bullet li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px;
}

.list-bullet li::before {
  content: " ";
  position: absolute;
  top: 8px;
  left: 0;
  width: 7px;
  border-radius: 100%;
  height: 7px;
  background: var(--mainColorDark);
}

.list-check {
  padding-left: 0;
  list-style: none;
}

.list-check > li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 7px;
}

.list-check > li::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: #00b517;
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
}

.list-normal {
  list-style: none;
  padding-left: 0;
}

.list-normal li {
  padding-left: 1em;
  margin-bottom: 7px;
}

.list-normal li:before {
  content: "•";
  color: var(--mainColorLight);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.list-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.list-menu li {
  margin-bottom: 5px;
}

.list-menu a {
  color: #141432;
}

.list-menu a:hover {
  color: var(--mainColorLight);
}

.cols-two {
  column-count: 2;
}

.cols-three {
  column-count: 3;
}

.cols-four {
  column-count: 4;
}

.icontext {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.icontext .icon {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}

.icontext small,
.icontext .title {
  display: block;
}

.tag {
  display: inline-block;
  padding: 3px 7px;
  background: #f2f2f2;
  border: 1px xolid #eee;
  border-radius: 3px;
  margin-top: 4px;
  margin-right: 2px;
  font-size: 85%;
}

.price {
  font-weight: 600;
}

.notify {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  border-radius: 3rem;
  color: #fff;
  background-color: #f30000;
}

.map-pin {
  border-radius: 3rem;
  background-color: red;
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.map-pin:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.3);
}

.icon-action {
  margin-top: 5px;
  float: right;
}

/* ====================== box ==================== */
.box {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(108, 117, 125, 0.2);
  background: #fff;
}

.box img {
  max-width: 100%;
}

.label-rating {
  margin-left: 7px;
  display: inline-block;
  vertical-align: middle;
}

/* rating-stars */
.rating-stars {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  clear: both;
  line-height: 0.8;
}

.rating-stars i {
  font-size: 14px;
  color: #ccc;
  display: inline;
}

.rating-stars img {
  height: 16px;
  max-width: none;
}

.rating-stars li {
  display: block;
  text-overflow: clip;
  white-space: nowrap;
  z-index: 1;
}

.rating-stars li.stars-active {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.rating-stars li.stars-active i {
  color: orange;
}

.rating-stars.stars-lg img {
  height: 24px;
  max-width: none;
}

.form-inline input[type="number"] {
  max-width: 100px;
}

.form-control:hover,
.form-select:hover {
  border-color: var(--mainColorLight);
}

.btn-facebook {
  background-color: #405d9d;
  color: #fff;
}

.btn-facebook:hover {
  color: #fff;
}

.btn-instagram {
  background-color: #e52d27;
  color: #fff;
}

.btn-instagram:hover {
  color: #fff;
}

.btn-youtube {
  background-color: #c8046c;
  color: #fff;
}

.btn-youtube:hover {
  color: #fff;
}

.btn-twitter {
  background-color: #42aeec;
  color: #fff;
}

.btn-twitter:hover {
  color: #fff;
}

.btn-google {
  background-color: #ff7575;
  color: #fff;
}

.btn-google:hover {
  color: #fff;
}

.btn-icon {
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.btn-icon:hover {
  background-color: rgba(108, 117, 125, 0.15);
}

.btn-icon:focus {
  box-shadow: none;
}

.btn-light {
  background-color: #fff;
  border-color: rgba(108, 117, 125, 0.25);
}

.btn-light i {
  color: #adb5bd;
}

.btn-light:hover {
  background-color: #fff;
  border-color: #adb5bd;
}

.btn-drag {
  padding: 5px 2px;
  display: inline-block;
  cursor: ns-resize !important;
}

.categories-table-container {
  max-width: 1300px; /* Set your desired max height */
  overflow-x: auto; /* Enable vertical scrolling if content exceeds max height */
}

@media (max-width: 767px) {
  .custom-select {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .custom-select {
    width: 180px;
  }
}
@media (max-width: 767px) {
  .custom-input {
    width: 14rem;
  }
  /* .col-auto{
    display: flex;
  }
  .container{
    display: flex;
    justify-content: center;

  } */
}

@media (max-width: 640px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }

  .stack-on-small {
    width: 100%;
  }
}

.btn-sm i {
  font-size: 1.2rem;
  line-height: 0.7;
  vertical-align: bottom;
}

.table-container {
  margin: auto;
  padding: 16px;
  overflow-x: auto;
  /* Enable horizontal scrolling on small screens */
}

.table {
  width: 100%;
  border-collapse: collapse;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  /* Combine borders for smaller screens */
}
.cursorpointer {
  cursor: pointer;
}
.cursordefault {
  cursor: text;
}
.table th,
.table td {
  align-items: flex-start;
  align-content: start;
  /* cursor: default; */

  padding: 12px;
  /* Reduce padding for smaller screens */
  border: 1px solid #ddd;
  justify-content: center;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}

.tabletd td {
  text-align: center;
  align-items: center;
  align-items: center;
  align-content: center;
}

.table td div {
  /* text-align: start; */
  align-items: center;
  /* width: 100%; */
  /* display: ; */
  /* honnnnnn */
  justify-content: center;
  /* align-self: center; */
}
.flex {
  display: flex;
}

.align-center {
  align-items: flex-start;
}

/* .table td div span{
  align-items: center;
  display: inline-block ;
  justify-content: center;
} */
.table tbody tr:hover {
  /* background-color: #f8f8f8; */
}

.table td img {
  width: 200px;
  height: 140px;
  object-fit: fit;
}

/* Media Queries for responsiveness */
@media only screen and (max-width: 1134px) {
  .table th,
  .table td {
    padding: 8px;
    /* Further reduce padding for smaller screens */
  }
  .table td img {
    width: 110px;
    height: 110px;
    object-fit: fit;
  }
}

@media only screen and (max-width: 670px) {
  .table th,
  .table td {
    padding: 6px;
    /* Further reduce padding for smallest screens */
    /* font-size: 12px; */
    /* Reduce font size for smaller screens */
  }
  .table td img {
    width: 100px;
    height: 100px;
    object-fit: fit;
  }
}

.clear-filter-btn button {
  background-color: #ff0000;
  color: #fff;
  padding: 7px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-filter-btn button:hover {
  background-color: red;
  transition: all 0.3s ease-in-out;
  color: white;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.pagination li {
  margin: 0 4px;
  display: inline-block;
  text-decoration: none;
}

.pagination a {
  display: inline-block;
  padding: 5px 16px;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #333;
  /* color: #ddd; */
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination a:hover {
  background-color: #ddd;
  text-decoration: none;
  /* color: greenyellow; */
}

.pagination .active a {
  background-color: var(--mainColorDark);
  color: white;
  text-decoration: none;
}

.pagination .disabled a {
  pointer-events: none;
  background-color: #eee;
  color: #aaa;
  text-decoration: none;
  /* color: greenyellow; */
}

.list-unstyled {
  height: 70px;
  overflow-y: auto;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.delete-confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-family: "Arial", sans-serif;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* max-width: 300px; */
  margin: auto;
  padding: 20px;
  text-align: center;
}

.delete-confirmation-dialog p {
  margin-bottom: 20px;
  font-size: 16px;
}

.delete-confirmation-dialog button {
  cursor: pointer;
  background-color: white;
  /* color: #fff; */
  /* border: none; */
  border: 1px solid;
  border-radius: 0.5rem;
  padding: 8px 15px;
  font-size: 14px;
  margin: 0 10px;
  /* transition: background-color 0.3s; */
}

.delete-confirmation-dialog button {
  color: var(--mainColorDark);
  border-color: var(--mainColorDark);
  /* background-color: #d32f2f; */
}
.delete-confirmation-dialog button:hover {
  color: white;
  border-color: var(--mainColorDark);
  transition: all 0.4s ease-in-out;
  background-color: var(--mainColorDark);
}

.delete-confirmation-dialog button.cancel {
  /* background-color: var(--mainColorLight); */
  color: red;
  border-color: red;
}
.delete-confirmation-dialog button.cancel:hover {
  /* background-color: var(--mainColorLight); */
  border-color: red;
  color: white;
  transition: all 0.4s ease-in-out;
  background-color: red;
}
@media (max-width: 767.98px) {
  .custom-input .search {
    width: 2rem;
  }
}

.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #4CAF50; */
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadein 0.2s, fadeout 0.5s 2.5s;
  width: 60%;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 20px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 20px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.pagination .active-page a {
  text-decoration: none;
  background-color: var(--mainColorDark);
  color: #fff;
}

.delete-order-button {
  background-color: #ff0000;
  /* Red background color */
  color: #ffffff;
  /* White text color */
  border: none;
  /* Remove border */
  padding: 7px 19px;
  /* Add padding */
  font-size: 16px;
  /* Set font size */
  cursor: pointer;
  /* Add cursor pointer */
  border-radius: 5px;
  /* Add border radius */
  transition: background-color 0.3s;
  /* Add transition effect */
}

.delete-order-button:hover {
  background-color: #cc0000;
  /* Darker red background color on hover */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.permissions-container {
  display: flex;
  flex-wrap: wrap;
}

.permission-item {
  box-sizing: border-box;
  /* Include padding and border in the width */
  padding: 5px;
  /* Adjust padding as needed */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
  /* Display ellipsis for overflowing text */
}

/* Large screens (lg) */
@media (min-width: 1391px) {
  .permission-item {
    width: 33.33%;
    /* Each permission item occupies 33.3333% of the container width to create 3 columns */
  }
}

/* Medium screens (md) */
@media (max-width: 1390px) {
  .permission-item {
    width: 50%;
    /* Each permission item occupies 50% of the container width to create 2 columns */
  }
}

/* Small screens (sm) */
@media (max-width: 767.98px) {
  .permission-item {
    width: 100%;
    /* Each permission item occupies 100% of the container width to create 1 column */
  }
}

.loader {
  border: 4px solid transparent;
  border-top: 4px solid var(--mainColorDark);
  border-radius: 50%;
  width: 23px;
  height: 23px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top: 4px solid var(--mainColorDark);
  }

  25% {
    border-top: 4px solid transparent;
    border-right: 4px solid var(--secondaryColor);
  }

  50% {
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--mainColorDark);
  }

  75% {
    border-bottom: 4px solid transparent;
    border-left: 4px solid var(--secondaryColor);
  }

  100% {
    transform: rotate(360deg);
    border-left: 4px solid transparent;
  }
}

/* Add this styling to your CSS file or style block */

.custom-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: #cccccc54;
}

.custom-btn.edit-btn {
  background-color: var(--mainColorDark);
  color: #fff;
}

.custom-btn.delete-btn {
  background-color: #dc3545;
  color: #fff;
}
.action-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: transparent;
}
.action-btn.edit-btn {
  /* background-color: var(--mainColorLight); */
  color: var(--mainColorDark);
}
.action-btn.delete-btn {
  /* background-color: #dc3545; */
  color: #dc3545;
}

.action-btn.delete-btn:hover {
  background-color: #dc3545;
  color: #fff;
}

/* Sidebar container */
.navbar-aside {
  /* background-color: #23232e; */
  /* color: #fff; */
  padding: 0.5rem;
  height: 100%;
  overflow-y: auto;
}

/* Brand logo */
.brand-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  height: 46px;
}

/* Minimize button */
.btn-aside-minimize {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
}

/* Menu styling */
.menu-aside {
  list-style-type: none;
  padding: 0;
}

.menu-item {
  font-size: 1.03rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.menu-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.menu-link:hover {
  background-color: #4d4d59;
  /* Set your desired hover background color */
}
.nofound {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}
.menu-link.active {
  /* background-color: #34343f;  */
}

/* Icons */
.icon {
  margin-right: 10px;
}

/* Divider styling */
.divider {
  margin: 10px 0;
  height: 1px;
  background-color: #4d4d59;
}

/* Heading styling */
.menu-heading {
  font-size: 1.03rem;
  font-weight: bold;
  color: var(--mainColorDark);
  margin-bottom: 5px;
  padding: 10px 10px 10px 5px;
}

.edit-order-button {
  background-color: var(--mainColorDark);
  border: none;
  color: #fff;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.edit-order-button:hover {
  background-color: var(--mainColorDark);
  color: #fff;
}

.custom-dropdown-arrow {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #6c6c6c;
  position: absolute;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
}
.order-btn {
  background-color: white;
  border: none;
  color: var(--mainColorDark);
  padding: 9px 26px;
  margin: 0 2px 0 5px;
  width: 8rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
  font-weight: 500;
}
.flexin {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  .order-btn {
    margin: 8px 4px 0 0;
  }
}
@media (max-width: 767.98px) {
  .flexin {
    display: inline-block;
    margin: 0 0px 0px 4px;
  }
}
.flexin-product {
  /* border-right: #000 1px solid; */
  display: flex;
}
.flexin-product .item {
  border-right: #000 1px solid;
  margin-left: 1rem;
  width: 10rem;
  /* background-color: #000; */
}
.flexin-product .item-unit {
  margin: 0rem 1rem 0rem 0rem;
  width: 13rem;
  /* min-width: 13rem; */
  /* background-color: #333; */
}
.flexin-product .item-unit-qty {
  margin: 0rem 1rem 0rem 0rem;
  width: 7rem;
  /* max-width: 7rem; */
  /* background-color: #00b517; */
}
.custom-select-order {
  width: auto;
  min-width: 13rem;
  /* width: ; */
  /* width: fit-content; */
  /* width: min-content; */
  /* width: 17rem; */
}
.orderheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0;
}
@media screen and (max-width: 880px) {
  .orderheader {
    display: block;
  }
}
.current-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* font-size: large; */
  font-size: 1.5rem;
  /* margin: 0 1rem 0 0; */
}

.current-status-mobile {
  display: none;
}
@media screen and (max-width: 767.98px) {
  .current-status {
    /* display: none; */
    font-size: smaller;
    color: white;
    display: none;
    /* margin-top: 0.5rem; */
  }
  .current-status-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: large;
    margin-top: 0.5rem;
  }
}
.statusname {
  margin: 0 1rem 1rem 0;
}
@media screen and (max-width: 767.98px) {
  .custom-select {
    width: 7rem;
    max-width: auto;
    min-width: auto;
  }
}
.item-unit-checkbox {
  display: flex;
  width: 7rem;
  /* margin: 0rem 1rem 0rem 0rem; */
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #000; */
  /* width: 4rem; */
  /* margin: 0.5rem 0rem; */
}
.margindelete {
  margin: 0 0 0 1rem;
}
.custom-checkbox-input {
  /* width: 5.8rem; */
  height: 2.35rem;
  /* display: inline-block; */
  border: 1px solid var(--mainColorDark);
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}
.delete-unit-btn {
  /* width: 7rem; */
  /* width: 2.5rem; */
  /* height: 2.35rem; */
}
.custom-checkbox-input:checked {
  background-color: var(--mainColorLight);
  position: relative;
}

.custom-checkbox-input:checked::before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
}
@media (max-width: 767.98px) {
  .flexin-product {
    display: inline-block;
  }
  .flexin-product .item {
    margin-left: 0;
    width: 100%;
  }
  .flexin-product .item-unit {
    width: 100%;
    margin: 0rem 1rem 0.5rem 0rem;
  }
  .flexin-product .item-unit-qty {
    min-width: 100%;
    margin: 0rem 1rem 0.5rem 0rem;
  }
  .item-unit-checkbox {
    display: flex;
    margin: 0rem 1rem 0.5rem 0rem;
    /* align-items: center; */
    width: 100%;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: #d7b718; */
    /* margin: 1rem; */
  }
  .delete-unit-btn {
    width: 100%;
  }
  .custom-checkbox-input {
    width: 100%;
  }
}
.box-limit {
  transition: background-color 0.3s;
  width: 2.8rem;
  /* padding: 1rem; */
  height: 2.2rem;
  /* padding-bottom: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 0.25rem;
}

.box-limit.selected {
  background-color: var(--mainColorDark);
  color: #fff;
}
.box-limit:hover {
  background-color: #ddd;
  /* color: grey; */
}
.box-limit.selected:hover {
  background-color: var(--mainColorDark);
  color: black;
}

.flex-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  flex-grow: 1;
}

.limit-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.flexin-table {
  display: inline-flex;
  /* justify-content: center; */
  /* align-items: center; */
}

@media (max-width: 1130px) {
  .flexin-table {
    display: inline-block;
    /* margin: 0 0px 0px 4px; */
  }
}
.side-margin {
  margin-top: -0.5rem;
  margin-left: 1rem;
}

@media (max-width: 1130px) {
  .side-margin {
    margin-top: 0rem;
    margin-left: 0rem;
  }
}

/* .bg-image-section {
  background-image: url("./bg5.jpg");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  text-align: left;
  width: 100%;
}
.inner-wrap {
  height: 100vh;
}
.section {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-top: 75px;
  padding-right: 10px;
  padding-bottom: 75px;
  padding-left: 200px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: inherit;
}
.block {
  text-align: right;
  align-items: center;
  margin-right: 0px;
  background-color: rgba(232, 232, 232, 0.48);
  display: flex;
  width: 35rem;
  height: 20rem;
  flex-wrap: nowrap;
  flex-direction: column;
}
.block img {
  width: 60%;
  height: 50%;
  object-fit: cover;
} */

/* footer */
/* .footer {
  background-color: var(--mainColorLight);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  gap: 2.5rem;
  padding: 30px 50px;
}

.footer-content h4 {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}
.footer-content .download-logo {
  display: inline-flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.footer-content li {
  list-style: none;
  margin-bottom: 16px;
  text-decoration: none;
}

.footer-content li a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  transition: all 0.4s ease;
}

.footer-content li a:hover {
  color:  var(--secondaryColor);
}
.footer-content li a img {
  width: 8rem;
  height: fit-content;
}

.footer-content .logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content .logo-footer {
  width: 8rem;
  height: fit-content;
}

.footer-content p {
  color:  var(--secondaryColor);
  font-size: 1.2rem;
  line-height: 30px;
  margin: 20px 0;
}
.icons a {
  display: inline-block;
  margin-right: 17px;
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.4s ease;
}
.icons a:hover {
  color:  var(--secondaryColor);
}

@media (max-width: 767.98px) {
  .footer {
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  }
  .footer-content {
    align-items: center;
  }
  .footer-content .download-logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .footer-content p {
    align-items: center;
  }
  .footer-content h4 {
    font-size: 1.2rem;
    text-align: center;
  }
  .footer-content li {
    padding: 0;
    text-align: center;
  }
  .footer-content li a {
    font-size: 1rem;
    text-align: center;
  }
  .footer-content p {
    font-size: 1rem;
    text-align: center;
  }
  .icons {
    font-size: 1rem;
    text-align: center;
  }
  .footer-content .logo-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
} */

/* carousel */
.next-arrow {
  /* width: 25px;
  height: 25px; */
  position: absolute;
  top: 50%;
  right: 1rem;
  /* background: #000; */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.prev-arrow {
  /* width: 25px;
  height: 25px; */
  position: absolute;
  top: 50%;
  left: 1rem;
  /* background-color: #000; */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.arrow-svg {
  width: 30px;
  height: 30px;
}

.next-arrow svg:hover,
.prev-arrow svg:hover {
  background-color: var(--mainColorDark);
  transition: all 0.3s ease-in-out;
}

.next-arrow svg,
.prev-arrow svg {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.product-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1rem; */
}
.product-arrows {
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}
.prev-arrow-product {
  border: none;
  background-color: transparent;
}
.next-arrow-product {
  border: none;
  background-color: transparent;
}
.prev-arrow-product svg {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1.2px solid #000;
  /* background-color: transparent; */
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.next-arrow-product svg {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1.2px solid #000;
  /* background-color: transparent; */
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.next-arrow-product svg:hover,
.prev-arrow-product svg:hover {
  border-color: var(--mainColorDark);
  fill: var(--mainColorDark);
  transition: all 0.3s ease-in-out;
}

/* .carousel { */
/* height: 78vh; */
/* background-color: #141432; */
/* } */
/* @media screen and (max-width: 768px) {
  .carousel {
    height: 50vh;
  }
}
@media screen and (max-width: 590px) {
  .carousel {
    height: 30vh;
  }
} */
/* @media screen and (max-width: 990px) {
  .carousel {
    height: 50vh;
  }
} */
/* @media screen and (max-width: 1120px) {
  .carousel {
    height: 62vh;
  }
}
@media screen and (max-width: 1100px) {
  .carousel {
    height: 59vh;
  }
}
@media screen and (max-width: 990px) {
  .carousel {
    height: 54vh;
  }
}

@media screen and (max-width: 890px) {
  .carousel {
    height: 48vh;
  }
}
@media screen and (max-width: 768px) {
  .carousel {
    height: 40vh;
  }
}
@media screen and (max-width: 690px) {
  .carousel {
    height: 38vh;
  }
}
@media screen and (max-width: 590px) {
  .carousel {
    height: 32vh;
  }
}
@media screen and (max-width: 490px) {
  .carousel {
    height: 24vh;
  }
}
@media screen and (max-width: 390px) {
  .carousel {
    height: 20vh;
  }
} */

/* @media screen and (max-width: 800px) {
  .carousel {
    height: 60vh;
  }
} */

/* @media screen and (max-width: 767.98px) {
  .carousel {
    height: 50vh;
  }
} */

.carousel {
  /* position: absolute; */
  /* max-width: 100vw; */
  /* max-height: 80vh; */
  min-height: 30vh;
  padding-top: 0.5rem;
  margin: 0 auto;
  /* width: 100%; */
  border-radius: 0.8rem;
  /* padding: 0.5rem 0.2rem 0rem 0.2rem; */
}
.bradius {
  border-radius: 0.8rem;
  /* height: 50vh; */
}
@media screen and (max-width: 768px) {
  .carousel {
    min-height: 27vh;
    border-radius: 0rem;
  }
  .bradius {
    border-radius: 0rem;
  }
}
@media screen and (max-width: 490px) {
  .carousel {
    min-height: 18vh;
    border-radius: 0rem;
  }
  .bradius {
    border-radius: 0rem;
  }
}

/* .carousel img { */
/* width: 100vw; */
/* height: 70vh; */
/* border-radius: 8px; */
/* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
/* } */

/* features section 2*/
.features {
  /* display: flex; */
  /* padding: 0rem 3rem; */
  background-color: #f3f4f6;
  padding: 3rem 3.3rem;
}

.section-title {
  /* font-size: 1.5rem; */
  font-weight: bold;
  margin-bottom: 2rem;
}

.feature-grid {
  display: grid !important;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.feature-item {
  background-color: white;
  padding: 1.5rem;
  /* border-color: #000; */
  border-radius: 0.375rem;
  box-shadow: 1px 2px 10px 4px rgba(130, 130, 130, 0.215);
  text-align: center;
}

.feature-icon {
  font-size: 3rem;
  color: var(--mainColorDark);
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.feature-description {
  font-size: 1rem;
  color: black;
}
/* about us section 3*/
/* .about {
  background-color: white;
  padding: 3rem 3.3rem;
}

.about-grid {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
} */

.about-description {
  /* color: #4a5568; */
}
/* .aboutus-img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: fit;
  align-items: center;
  /* background-color: #000; */
/* justify-content: center;
  padding-left: 4rem;
}  */

/* @media screen and (max-width: 767.98px) {
  .about {
    padding: 3rem 2rem;
  }

  .aboutus-img {
    padding-left: 0rem;
    /* gap: 1.5rem; */
/* padding-right: 4rem; */
/* background-color: #000; */
/* }
}  */
/* navbar */
.mini-logo-header {
  display: flex;
  align-items: center;
  /* margin-left: 2.9rem; */
  height: fit-content;
}
.logo-name {
  /* margin-top: 0.6rem; */
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
}
.mini-logo-header div {
  /* margin-left: 0.5rem; */
  /* margin: 1.5rem 0rem 0rem 0rem; */
  font-weight: bold;
  width: fit-content;
  /* background-color: #000; */
}
.mini-logo-header a {
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  width: 11rem;
  height: 3.2rem;
  /* background-color: #000; */
}
.mini-logo-header img {
  width: 11rem;
  height: 3.2rem;
  display: flex;
  object-fit: fit;
  align-items: center;
  justify-content: center;
}
.mini-logo-header .iconheader {
  width: 3rem;
}
.mini-logo-header .nameheader {
  width: 6rem;
}
.mini-logo-footer {
  width: 2rem;
  display: flex;
  align-items: center;
  /* margin-left: 0.89rem; */
  height: 2rem;
  /* background-color: #000; */
}

.mini-logo-footer img {
  width: 2rem;
  height: 100%;
  display: flex;
  object-fit: fit;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767.98px) {
  .mini-logo-header {
    /* width: 5rem; */
    margin-left: 0;
  }
  .mini-logo-header div {
    /* width: 5rem; */
    width: fit-content;
    display: none;
  }
  .mini-logo-footer {
    width: fit-content;
    margin-left: 0;
  }
  .mini-logo-footer img {
    width: 2rem;
    /* width: fit-content; */
    margin-left: 0;
  }
}

.header-div {
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.header {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.nav {
  /* background-color: black; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  /* background-color: #000; */
}
.nav a {
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0 0.7rem;
  color: var(--mainColorDark);
  text-decoration: none;
  cursor: pointer;
}

.nav a:hover {
  color: var(--mainColorDark);
  transition: all 0.3s ease-in-out;
}

.header .nav-btn {
  /* padding: 0 1rem; */
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  /* color: var(--textColor); */
  visibility: hidden;
  opacity: 0;
  /* font-size: 1.8rem; */
}

.header div,
.nav {
  display: flex;
  align-items: center;
}
.nav a.selected {
  color: var(--mainColorDark);
}

@media (max-width: 540px) {
  .nav {
    /* width: 100%; */
    /* display: block; */
    /* display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 75%; */
    /* background-color: rgb(223, 13, 13); */
  }
  .header .nav-btn {
    padding: 0 1rem;
    visibility: visible;
    opacity: 1;
    color: black;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.nav .nav-logo-mobile {
  display: none;
}

@media only screen and (max-width: 970px) {
  .header .nav-btn {
    padding: 0 1rem;
    visibility: visible;
    /* position: relative; */
    opacity: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* padding-right: 12rem; */
    /* background-color: red; */
  }
  .nav a {
    color: var(--mainColorDark);
  }

  .nav a:hover {
    color: var(--mainColorDark);
  }
  .nav a.selected {
    color: var(--mainColorDark);
  }

  .header .nav {
    position: fixed;
    top: -150vh;
    left: 0;
    /* right: 100vw; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgba(255, 255, 255, 0.968);
    transition: 1s;
  }

  .header .responsive_nav {
    transform: translateY(150vh);
  }

  .nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .nav .nav-logo-mobile {
    display: block;
    position: absolute;
    top: 0.8rem;
    left: 2rem;
  }

  .nav a {
    color: #000;
    /* font-size: 1.5rem; */
  }
}
/* @media screen and (max-width: 1176px) {
  .header .nav {
    background-color: white;
  }
} */
/* home section one */
.hero {
  /* background-color: var(--mainColorLight); */
  /* background-image: url('/public/images/home.png');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  /* padding: 8rem; */
  text-align: center;
  width: 100%;
}

.hero img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

.hero-title {
  /* font-size: 2rem; */
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

.hero-subtitle {
  /* font-size: 1.125rem; */
  color: white;
  margin-bottom: 2rem;
}

.order-button {
  display: inline-block;
  padding: 0.625rem 1.5rem;
  border-radius: 0.375rem;
  background-color: white;
  color: var(--mainColorDark);
  text-decoration: none;
  font-weight: medium;
}

.order-button:hover {
  background-color: #e2e8f0;
  color: var(--mainColorDark);
}

/* login */
.login {
  background-color: white;
  padding: 3rem 3.3rem;
}

.btn-login {
  background-color: var(--mainColorDark);
  color: white;
  padding: 0.625rem 1.5rem;
  border-radius: 0.375rem;
  text-decoration: none;
}
.btn-login:hover {
  background-color: var(--mainColorLight);
  color: white;
  transition: all 0.3s ease-in-out;
}
.bgimage {
  background-image: url("../public/images/bannerplaceholder.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  /* absolute 
  bg-cover 
  bg-center 
  w-full 
  h-full */
}
@media screen and (max-width: 786px) {
  .bgimage {
    border-radius: 0rem;
  }
}
.login-container {
  background-color: white;
  overflow: hidden;
  min-height: 91vh;
  background-image: url("../public/images/test1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 786px) {
  .login-container {
    min-width: 100vw;
    height: 100vh;
  }
}
.loginimage {
  display: sticky;
  position: absolute;
  width: fit-content;
  /* height: 100%; */
  /* background-color: #000; */
  /* z-index: 1; */
  /* opacity: 0.5; */
  /* top: 0; */
  top: 4rem;
  left: 4rem;
}
.logincard {
  min-width: 25rem;
  border-radius: 0.8rem;
  /* background-color: #00b517; */
}
@media screen and (max-width: 786px) {
  .loginimage {
    top: 0;
    left: 0;
  }
  .logincard {
    min-width: 20rem;
  }
}
/* download app */
.downloadapp {
  background-color: white;
  padding: 3rem 3.3rem;
}

.downloadapp-grid {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.downloadapp-description {
  color: #4a5568;
}

.img-container {
  display: inline-flex;
  width: 100%;
  height: auto;
  /* background-color: #000; */
  margin: 0 2rem;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-top: 2rem; */
}
.downloadapp-img {
  width: 80%;
  height: 60%;
  /* background-color: #000; */
}
.img-container .downloadapp-img img {
  /* border-radius: 0.5rem; */
  width: 100%;
  height: fit-content;
  object-fit: fit;
  aspect-ratio: 5 / 5;
}
.downloadlogos {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #00b517; */
  align-items: flex-start;
  justify-content: center;
  /* justify-content: space-between; */
  gap: 1rem;
  /* margin-top: 6rem; */
}

.downloadlogos img {
  width: 100%;
  height: auto;
}

.img-container a {
  width: 60%;
  height: fit-content;
  cursor: pointer;
}

/* quality assured policy */
/* .bg {
  background-color: #ededed;
  border-radius: 0.8rem;
} */

.bgcolor1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f4f4f490;
  border-radius: 0.8rem;
}

.bg {
  background-color: #f1f1f1;
  border-radius: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 786px) {
  .bgcolor1 {
    border-radius: 0rem;
  }

  .bg {
    border-radius: 0rem;
  }
}

.privacy {
  background-color: #f3f4f6;
  padding: 3rem 3.3rem;
}

@media (max-width: 1000px) {
  .img-container a {
    width: 90%;
    height: fit-content;
    cursor: pointer;
  }
  .downloadapp-img {
    width: 100%;
    height: 60%;
    /* background-color: #000; */
  }
  .img-container .downloadapp-img img {
    /* border-radius: 0.5rem; */
    width: 100%;
    height: fit-content;
    object-fit: fit;
    aspect-ratio: 5 / 5;
  }
  .downloadlogos {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 70%;
    
    flex-direction: column;
    background-color: #00b517;
    align-items: flex-start; */
    /* justify-content: space-between; */
    /* gap: 1rem; */
    margin-top: 0rem;
  }
}

/* footer 2 */
.footer-div {
  width: 100%;
  border-top: 1px solid #e2e8f0;

  /* background-color: #00b517; */
}

.footer {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  /* background-color: black; */
  padding: 1rem 0 1rem 0.5rem;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  color: var(--mainColorDark);
}

.footer-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  font-size: small;
  justify-content: space-between;
}

.footer-items {
  display: flex;
  align-items: center;
  font-size: small;
  /* background-color: #b50000; */
  /* justify-content: space-evenly; */
  gap: 0.5rem;
}
.footer .brand {
  /* font-weight: bold; */
  /* font-size: 1.25rem;  */
  /* width: 100%; */
  height: 100%;
  margin-right: 3rem;
  margin-left: 1rem;
  /* padding: 0 3rem; */
  /* background-color: rgb(255, 0, 0); */
}
.brand img {
  width: 8rem;
  height: 5rem;
  /* background-color: #000; */
}
.footer nav {
  margin-top: 0.5rem;
  /* background-color: #b50000; */
  display: none;
}

.footer nav a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1.5rem;
}

.footer nav a:hover {
  /* text-decoration: underline; */
  color: var(--secondaryColor);
  transition: all 0.3s ease-in-out;
}

/* .footer .button {
  display: flex;
  align-items: center;
  border: 1px solid white;
  padding: 0.25rem 0.5rem;
  color: white;
  cursor: pointer;
} */

/* .footer .button:hover {
  background-color: rgba(255, 255, 255, 0.1);
} */

/* .footer .button .icon {
  height: 1.25rem; 
  width: 1.25rem; 
  margin-right: 0.5rem; 
} */

@media (min-width: 785px) {
  .footer {
    /* padding-left: 2rem;
    padding-right: 2rem; */
  }

  .footer nav {
    display: flex;
  }
}

/* @media (min-width: 1024px) {
  .footer {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
} */
.footer-content .download-logos {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  /* background-color: #000; */
}
.footer-content .download-logos img {
  width: 7rem;
  height: 2rem;
}
.footer-content .download-logos a {
  /* background-color: #00b517; */
  padding: 0.2rem 0.2rem 0 0;
  /* color: white; */
  width: 7rem;
  height: 2rem;
  text-decoration: none;
}
.downloadbutton {
  background-color: #fff;
  /* padding: 0rem 0rem; */
  /* margin: 1rem 0; */
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.375rem;
  color: var(--mainColorDark);
  text-decoration: none;
}

.downloadbutton svg {
  /* margin-right: 0.5rem; */
  fill: var(--mainColorLight);
}
.downloadbutton:hover {
  background-color: #e2e8f0;
  transition: all 0.3s ease-in-out;
  color: var(--mainColorLight);
}
@media (max-width: 768px) {
  .footer-content .download-logos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    /* width: 2rem; */
    /* font-size: x-small; */
    padding: 0 0.4rem 0 0.4rem;
    /* background-color: rgb(255, 0, 0); */
    /* height: 100%; */
  }
  .footer-content .download-logos img {
    width: 5rem;
    height: 1.8rem;
  }
  .footer-content .download-logos a {
    /* background-color: #00b517; */
    padding: 0;
    /* color: white; */
    width: 5rem;
    /* height: 1rem; */
    text-decoration: none;
  }
  .downloadbutton svg {
    /* margin-right: 0.5rem; */
    fill: white;
  }
  .downloadbutton {
    background-color: transparent;
    /* background-color: rgb(255, 7, 7); */
    /* width: 3.6rem;
    height: 3rem; */
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    border-radius: 0.375rem;
    color: white;
    text-decoration: none;
  }
  .download-footer {
    padding: 0 0.1rem 0 0;
  }
}
/* ordering */
.order-section {
  background-color: white;
  padding: 3rem 3.3rem;
}
.order-section-bg {
  background-color: white;
  padding: 3rem 3.3rem;
  /* padding-top: 3rem;
  padding-bottom: 3rem; */
}
.ordering-p {
  font-size: 1.4rem;
  font-weight: 700;
  /* color: var(--mainColor); */
  /* text-decoration: underline; */
}
@media (max-width: 768px) {
  .download-logos span {
    display: none;
  }
  .order-section-bg {
    padding: 3rem 2rem;
  }
  .footer-items {
    font-size: x-small;
  }
  .download-logos {
    font-size: x-small;
  }
}
.playstoremargin {
  margin-top: 0.15rem;
}
.order-section-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
}

/* @media (min-width: 768px) {
  .order-section-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1024px) {
  .order-section-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
} */

.order-section-title {
  /* font-size: 1.5rem; */
  font-weight: bold;
  /* margin-bottom: 2rem; */
}

/* @media (min-width: 768px) {
  .order-section-title {
    font-size: 1.875rem;
  }
}

@media (min-width: 1024px) {
  .order-section-title {
    font-size: 2.25rem;
  }
} */

.order-section-box {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}
.side {
  margin-left: 1.5rem;
}
@media (max-width: 768px) {
  .order-section-box {
    padding: 1rem;
  }
  .side {
    margin-left: 0;
  }
}

.order-section-timeline {
  position: relative;
  padding-left: 1.5rem;
}

.order-section-grid {
  display: grid;
  gap: 1.5rem;
}

.order-section-item {
  display: grid;
  gap: 0.25rem;
  /* font-size: 0.875rem; */
  position: relative;
}

.order-section-marker {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--mainColorDark);
  border-radius: 9999px;
  position: absolute;
  left: 0;
  transform: translateX(-29.5px);
  z-index: 10;
  top: 0.25rem;
}

.order-section-item-title {
  font-weight: 600;
}

.order-section-item-description {
  color: #6b7280;
}

/* src/PromoCard.css */
/* src/PromoCard.css */
.promo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 3rem 1rem;
}

.grid-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  gap: 1rem;
}

.fruit-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fruit-img {
  margin-bottom: 1rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.text-container {
  background-color: #a3bcb6;
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 2rem;
  color: white;
}

.text-container h2 {
  font-size: 2rem;
  font-weight: bold;
}

.text-container p {
  margin-top: 1rem;
  font-size: 1.125rem;
}

.header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  /* background-color: #000; */
  /* padding-right: 10rem; */
  /* height: 2rem; */
  /* width: 100%;
  height: 100%; */
  /* object-fit: cover; */
}
@media screen and (max-width: 768px) {
  .header-image {
    width: 0rem;
  }
  .header-image img {
    width: 0rem;
  }
}
.img-icon {
  width: 11rem;
  /* height: 100%; */
  object-fit: cover;
}

.header-image img {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}
.spin {
  color: var(--mainColorLight);
}

/* contact us */
.contactus {
  padding: 3rem 3.3rem;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .contactus {
    padding: 3rem 2rem;
  }
}
.contact-info-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

.tripoli-section-contact .icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--mainColorDark);
  /* fill: var(--mainColorLight); */
  /* margin-right: 10px; */
}

.send-message-btn {
  background-color: var(--mainColorDark);
  color: #fff;
  /* padding: 10px 20px; */
  margin-top: 0.5rem;
  /* margin-left: 10rem; */
  /* margin: ; */
  font-weight: lighter;
  font-size: 0.9rem;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
@media screen and (max-width: 768px) {
  .send-message-btn {
    width: 100%;
  }
}
.send-message-btn:hover {
  background-color: var(--mainColorLight);
}

.form-container {
  /* background-color: #000; */
  width: 100%;
  /* margin-top: 40px; */
  /* padding-bottom: 20px; */
  /* padding-right: 5rem; */
  /* background-color: #000; */
  padding: 3.2rem 0 0 2rem;
  /* padding-left: 10rem; */
}

.form-container form {
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.form-container form .mb-4 {
  margin-bottom: 20px;
}

.form-container form label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
}

.form-container form input[type="text"],
.form-container form input[type="email"],
.form-container form textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  border-radius: 4px;
}

.form-container form textarea {
  resize: vertical;
}

.form-container form button[type="submit"] {
  /* background-color: #333; */
  color: #fff;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container form button[type="submit"]:hover {
  background-color: #9fc73ac6;
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 0.1rem 0;
  }
}

.mail-link {
  color: #333;
  text-decoration: none;
}
.mail-link:hover {
  color: var(--mainColorLight);
  transition: all 0.3s ease-in-out;
}

.quality-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15rem;
  margin-top: -4rem;
}
.quality-img-container img {
  width: 70%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .quality-img-container {
    margin-left: 5rem;
    margin-top: 0rem;
  }
  .quality-img-container img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .quality-img-container {
    margin-left: 0;
    margin-top: 0rem;
  }
  .quality-img-container img {
    width: 100%;
  }
  .privacy {
    padding: 3rem 2rem;
    /* background-color: #f3f4f6; */
  }
}

.text-color {
  color: var(--mainColorDark);
}
/* delivery */
.delivery {
  background-color: #f3f4f6;
  padding: 3rem 3.3rem;
}

@media screen and (max-width: 768px) {
  .delivery {
    padding: 3rem 2rem;
  }
}
.link {
  color: var(--mainColorDark);
  text-decoration: none;
}

.link:hover {
  color: var(--mainColorDark);
  text-decoration: underline;
}
.telephone {
  text-decoration: none;
  color: black;
}
.telephone:hover {
  color: var(--mainColorDark);
  transition: all 0.3s ease-in-out;
}

.delivery-img {
  /* width: 80%; */
  /* height: 80%; */
  margin: 10rem 0rem 0rem 15rem;
  object-fit: fill;
}
.delivery-img img {
  width: 70%;
  height: 60%;
  object-fit: fill;
}

@media screen and (max-width: 768px) {
  .next-arrow {
    /* hidden */
    display: none;
  }

  .prev-arrow {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .delivery-img {
    margin-left: 0;
  }
  .delivery-img img {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .carousel img {
    width: 100vw;
    /* height: 70vh; */
  }
}
@media screen and (max-width: 768px) {
  .carousel img {
    width: 100vw;
    /* height: 50vh; */
  }
}
@media screen and (max-width: 500px) {
  .carousel img {
    width: 100vw;
    /* height: 35vh; */
  }
}

/* order details */
.bgcolor {
  background-color: var(--mainColorDark);
}
.textcolor {
  color: var(--mainColorDark);
}
.card-header-order {
  background-color: whitesmoke;
  height: 5.2rem;
  position: relative;
  /* height: 100px; */
  /* background-color: var(--mainColorDark) !important; */
  /* text-align: center; */
  /* background-color: var(--mainColorLight); */
  /* color: var(--mainColorDark); */
}

@media screen and (max-width: 899px) {
  .card-header-order {
    height: 7.2rem;
  }
}

.btnhoverdark {
  background-color: var(--mainColorDark);
  color: white;
}
.btnhoverdark:hover {
  background-color: var(--mainColorLight);
  transition: all 0.3s ease-in-out;
  color: white;
}
.btnhover:hover {
  background-color: var(--mainColorLight);
}

.cursorhand {
  cursor: default;
}

.input-field:focus {
  outline: var(--mainColorDark);
  /* outline: none; */
  border-color: var(--mainColorDark);
}

.send-message-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: red;
}
.success {
  color: green;
}

.enabled {
  background-color: green;
}
.disabled {
  background-color: rgba(255, 0, 0, 0.088);
}
/* .disabled:hover{
  background-color: rgba(164, 164, 164, 0.139);
} */
.privacyPolicy {
  line-height: 1.6;
  margin: 0 auto;
}

.privacyPolicy h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.privacyPolicy h2 {
  color: var(--mainColorLight);
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 10px;
}

.privacyPolicy p {
  margin-bottom: 15px;
}

.privacyPolicy ul {
  margin-bottom: 15px;
}

.privacyPolicy strong {
  font-weight: bold;
}

.input-field:focus {
  outline: var(--mainColorDark);
  /* outline: none; */
  border-color: var(--mainColorDark);
}

.send-message-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 0.9rem;
}
.success {
  color: green;
  font-size: 0.9rem;
}

.enabled {
  background-color: green;
}
.disabled {
  background-color: rgba(255, 0, 0, 0.088);
}
/* .disabled:hover{
  background-color: rgba(164, 164, 164, 0.139);
} */
.save-btn {
  width: fit-content;
  padding: 0rem 0.5rem 0rem 0rem;
  cursor: pointer;
  /* margin-top: 1rem; */
}

@media screen and (max-width: 768px) {
  .save-btn {
    padding: 0rem 0rem 0rem 0rem;
  }
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.required-field {
  border: 1px solid red;
  border-radius: 0.3rem;
  /* position: relative;  */
}

.required-content::after {
  content: "*";
  color: red;
  margin-left: 0.2rem;
  /* width: 20rem;
  height: 20rem; */
  /* position: absolute; */
  /* top: -5px;  */
  /* right: -10px;  */
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2rem;
}
.delete-image-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  /* right: 5px; */
}
.custom-btn {
  /* background-color: var(--mainColorDark); */
  background-color: white;
  color: red;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.container-width {
  /* center */
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.tripoli-section-contact {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 2rem 1rem 2rem 1rem;
}

.tripoli-content-contact {
  width: 100%;
  max-width: 50%;
  min-width: 50%;
  margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tripoli-section-contact {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 2rem 1rem 2rem 1rem;
  }

  .tripoli-content-contact {
    /* background-color: black; */
    width: 100%; /* Full width on small screens */
    max-width: 100%; /* Maximum width on medium screens and above */
    min-width: 100%;
    margin-bottom: 1rem;
  }
}

.tripoli-section {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 2rem 1rem 2rem 1rem;
  /* background-color: #00b517; */
}

.tripoli-content {
  width: 100%;
  max-width: 50%;
  min-width: 62%;
  margin-bottom: 1rem;
}
.tripoli-section-quality {
  /* background-color: #141432; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 2rem 0.4rem 2rem 1rem;
}

.section-quality-image {
  position: relative;
  /* max-width: 1200px; */
  padding: 0rem 0.5rem 0rem 0rem;
}

.section-quality-image img {
  display: block;
  max-width: 100%;
  border-radius: 0.8rem;
  /* filter: blur(1px); */
}

.overlay {
  /* padding: 0rem 0.5rem 0rem 0rem; */
  position: absolute;
  border-radius: 0.8rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background-color: rgba(255, 255, 255, 0.322);
}

.product-size img {
  /* width: 25rem; */
  /* height: 1rem; */
  /* display: flex;
  flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 2rem 0rem 2rem 1rem; */
  /* background-color: #00b517; */
}

/* .product-size img{
  width: 100%;
  height: 100%;
  object-fit: cover; */
/* border-radius: 0.8rem; */
/* background-color: #00b517; */
/* width: 10rem; */
/* margin: 0 1rem; */
/* height: auto; */
/* box-shadow: #000000 0px 0px 10px; */
/* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); */
/* margin-right: 16px;  */
/* } */

.slider {
  display: block;
  min-height: 22rem;
}
.bgimagebestselling {
  background-image: url("../public/images/productplaceholder.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  background-color: #f4f4f4;
  width: 220px;
  /* object-fit: fit; */
  height: 345px;
  border-radius: 0.8rem;
  /* absolute 
  bg-cover 
  bg-center 
  w-full 
  h-full */
}
.overflow-x-product {
  display: none;
}

/* Styles for the overflow-x on smaller screens */
@media screen and (max-width: 1145px) {
  .slider {
    display: none;
  }

  .overflow-x-product {
    display: flex;
    overflow-x: auto;
    min-height: 18rem;
  }

  .product-size {
    /* width: 100%; */
    /* background-color: #00b517; */
    height: 100%;
    flex: 0 0 auto;
    margin-right: 16px;
  }
  .product-size img {
    /* width: 210px; */
    /* height: auto; */
    object-fit: fit;
  }
  .product-arrows {
    display: none;
  }
}
.tripoli-text-quality {
  font-size: 1.18rem;
  padding: 1rem 1rem 1rem 1rem;
  /* font-weight: bold; */
  color: white;
  margin-bottom: 1rem;
}
.custom-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 5px 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-dot-active {
  background-color: var(--mainColorDark);
}
.product-box {
  background-color: white;
  /* background-color: #f4f4f490; */
  margin: 0.5rem;
  /* padding: 1rem; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);  */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.product-box-placeholder {
  /* background-color: white; */
  background-color: #f4f4f4;
  margin: 0.5rem;
  /* padding: 1rem; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);  */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
@media screen and (max-width: 1145px) {
  .product-box {
    width: 100%;
    margin: 0.1rem;
  }
}

.bestselling {
  width: 100%;
  /* height: 20rem; */
  /* background-color: #f3f4f6; */
  /* border-radius: 0.8rem; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 2rem 0rem 2rem 1rem;
  /* margin-bottom: 4rem; */
}
.bg-bestselling {
  /* background-color: #f3f4f6;
  border-radius: 0 0 0.8rem 0.8rem; */
  margin-bottom: 3rem;
  padding-bottom: 2rem;
}

.tripoli-content-quality {
  width: 100%;
  /* max-width: 50%; */
  min-width: 62%;
  margin-bottom: 1rem;
}
.tripoli-title {
  font-size: 2rem;
  font-weight: 600;
  /* color: #2d3748; */
}

.tripoli-text {
  font-size: 1.12rem;
  /* color: #718096; */
  margin-bottom: 1rem;
}

.tripoli-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tripoli-image {
  /* margin-left: 2rem; */
  width: 100%;
  height: 22rem;
  max-height: 25rem;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Responsive Design */
@media (max-width: 768px) {
  .tripoli-title {
    font-size: 1rem;
  }
  .tripoli-text {
    font-size: 0.125rem;
  }
  .tripoli-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 2rem 1rem 2rem 1rem;
  }

  .tripoli-content {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 1rem;
  }

  .tripoli-title {
    font-size: 2.25rem;
    font-weight: 700;
  }

  .tripoli-text {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .tripoli-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tripoli-image {
    margin-left: 0rem;
    width: 100%;
    /* height: 21rem; */
    max-height: auto;
  }
}

/* Container styles */
.container-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 960px; */
  margin: 0 auto;
  padding: 2rem 1rem;
}

.header-step {
  width: 100%;
  margin-bottom: 2rem;
  /* text-align: center; */
}

.title-step {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle-step {
  font-size: 1.125rem;
  color: #6b7280;
}

.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  border: 1px dashed var(--mainColorDark);
  /* background-color: #d1d5db;  */
  transform: translateY(-50%);
}

.step {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 1px solid var(--mainColorDark);
  border-radius: 50%;
}

.step.completed {
  background-color: #3b82f6;
  color: #fff;
}

.cards-step {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  width: 100%;
}

@media (min-width: 768px) {
  .cards-step {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .cards-step {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Card styles */
.card-step {
  text-align: center;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-header-step {
  margin-bottom: 1rem;
}

.icon-step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: 0 auto 0.5rem;
  color: var(--mainColorDark);
}

.card-title-step {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.card-description-step {
  font-size: 1rem;
  color: var(--mainColorDark);
  /* color: #6b7280;  */
}

.card-content-step {
  font-size: 1rem;
  /* color: #4b5563;  */
}

.card-icon-order {
  color: white;
  margin: 0 0.15rem 0 0.15rem;
  /* margin: 0 0.5rem 0 0.5rem; */
  /* background-color: #00b517; */
}
.card-order {
  background-color: white;
  border-radius: 0.8rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  text-align: center;
  height: 100%;
  box-shadow: 1px 2px 10px 4px rgba(130, 130, 130, 0.215);
}

.button-order {
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  margin-top: auto; /* Push the button to the bottom */
}

.button-order.primary {
  background-color: var(--mainColorDark);
  color: white;
}

.button-order.secondary {
  border: 1px solid var(--mainColorDark);
  color: white;
}

.button-order.primary:hover,
.button-order.secondary:hover {
  background-color: var(--mainColorLight);
}

.button-order.primary:focus-visible,
.button-order.secondary:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring);
}

.cards-order {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .cards-order {
    grid-template-columns: repeat(3, 1fr);
  }
}

.carousel-autoplay {
  min-height: 30rem;
  /* background-color: #00b517; */
}
@media screen and (max-width: 768px) {
  .carousel-autoplay {
    min-height: 15rem;
  }
}
@media screen and (max-width: 598px) {
  .carousel-autoplay {
    min-height: 10rem;
  }
}
.top-carousel {
  padding-top: 0.5rem;
  margin: 0 auto;
}
.carousel-div-autoplay {
  min-height: 520px;
  /* padding-top: 0.5rem; */
  /* margin: 0 auto; */
  /* width: 100%; */
  border-radius: 0.8rem;
  /* position: relative; */
  /* background-color: #f4f4f4; */
  /* color: #00b517; */
}
.carousel-div {
  min-height: 17vh;
  border-radius: 0rem;
}
@media screen and (max-width: 1200px) {
  .carousel-div-autoplay {
    min-height: 420px;
    border-radius: 0rem;
  }
}

@media screen and (max-width: 960px) {
  .carousel-div-autoplay {
    min-height: 380px;
    border-radius: 0rem;
  }
}
@media screen and (max-width: 768px) {
  .carousel-div-autoplay {
    min-height: 280px;
    border-radius: 0rem;
  }
}

@media screen and (max-width: 590px) {
  .carousel-div-autoplay {
    min-height: 200px;
    border-radius: 0rem;
  }
}
@media screen and (max-width: 390px) {
  .carousel-div-autoplay {
    min-height: 100px;
    border-radius: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .carousel-div {
    min-height: 12vh;
    border-radius: 0rem;
  }
}
@media screen and (max-width: 490px) {
  .carousel-div {
    min-height: 8vh;
    border-radius: 0rem;
  }
}

.overlay-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000004a;
}

.progress-bar {
  min-width: 20%;
  min-height: 20%;
  position: relative;
  padding: 20px;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  color: black;
}

.mb-2 {
  margin-bottom: 10px;
}

.progress {
  background-color: #4CAF50;
  color: white;
  height: 20px;
  padding-left: 4px;
  /* line-height: 20px; */
  text-align: center;
  border-radius: 5px;
}
